@font-face {
    font-family: 'IRANSans';
    src: url('./assets/fonts/IRANSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    direction: rtl;
    font-family: 'IRANSans', Tahoma, Arial, sans-serif;
    background-color: #f5f5f5;
}

* {
    box-sizing: border-box;
} 