.request-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: inherit;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #45a049;
}

.date-input-wrapper {
    position: relative;
    width: 100%;
}

.jalali-date-display {
    width: 100%;
    padding: 10px 35px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    min-height: 42px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.jalali-date-display::after {
    content: "📅";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.jalali-date-display:hover {
    border-color: var(--primary-main);
}

.date-picker-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.date-option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.date-option:hover {
    background-color: #f5f5f5;
}

/* Add scrollbar styling */
.date-picker-dropdown::-webkit-scrollbar {
    width: 8px;
}

.date-picker-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.date-picker-dropdown::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.date-picker-dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.date-picker-calendar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid var(--border-main);
    border-radius: 12px;
    margin-top: 5px;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 16px;
    width: 320px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
}

.calendar-header button {
    background: none;
    border: none;
    font-size: 24px;
    color: var(--primary-main);
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.calendar-header button:hover {
    background-color: var(--background-default);
    color: var(--primary-dark);
}

.calendar-header span {
    font-weight: bold;
    color: var(--text-primary);
    font-size: 1rem;
}

.calendar-weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    margin-bottom: 8px;
    padding: 0 4px;
}

.weekday {
    text-align: center;
    font-weight: bold;
    color: var(--text-secondary);
    padding: 8px 0;
    font-size: 0.85rem;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    padding: 4px;
}

.calendar-day {
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    color: var(--text-primary);
    position: relative;
}

.calendar-day:not(.other-month):hover {
    background-color: var(--primary-light);
    color: white;
}

.calendar-day.other-month {
    color: var(--text-disabled);
    cursor: default;
}

.calendar-day.today {
    background-color: var(--primary-main);
    color: white;
    font-weight: bold;
}

.calendar-day.today::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
    .date-picker-calendar {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 20px 20px 0 0;
        padding: 20px;
        box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
    }

    .calendar-header {
        margin-bottom: 24px;
    }

    .calendar-day {
        font-size: 1rem;
    }

    .weekday {
        font-size: 0.9rem;
    }
}

/* Animation for calendar popup */
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.date-picker-calendar {
    animation: slideUp 0.3s ease-out;
} 