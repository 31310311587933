.payment-result {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.result-icon {
    font-size: 48px;
    margin-bottom: 20px;
}

.success .result-icon {
    color: #4CAF50;
}

.error .result-icon {
    color: #f44336;
}

.result-details {
    margin: 20px 0;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.note {
    color: #e65100;
    font-weight: bold;
    margin-top: 15px;
}

.home-button,
.retry-button {
    margin: 10px;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: background-color 0.3s;
}

.home-button {
    background-color: #666;
    color: white;
}

.retry-button {
    background-color: #4CAF50;
    color: white;
}

.home-button:hover {
    background-color: #555;
}

.retry-button:hover {
    background-color: #45a049;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.payment-result {
    animation: fadeIn 0.5s ease-out;
} 