.toast-container {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.toast {
    min-width: 300px;
    max-width: 400px;
    background-color: var(--background-dark);
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(43, 108, 176, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideInFromLeft 0.3s ease-out;
}

.toast.success {
    border-left: 4px solid var(--success-main);
    background-color: rgba(56, 178, 172, 0.5);
    color: var(--success-dark);
}

.toast.error {
    border-left: 4px solid var(--error-main);
    background-color: rgba(229, 62, 62, 0.5);
    color: var(--error-dark);
}

.toast.warning {
    border-left: 4px solid var(--warning-main);
    background-color: rgba(237, 137, 54, 0.5);
    color: var(--warning-dark);
}

.toast.info {
    border-left: 4px solid var(--primary-main);
    background-color: rgba(43, 108, 176, 0.5);
    color: var(--primary-dark);
}

.toast-content {
    flex-grow: 1;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 0.95rem;
}

.toast-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: currentColor;
    opacity: 0.7;
    padding: 0 0.5rem;
    transition: all 0.2s ease;
}

.toast-close:hover {
    opacity: 1;
    transform: scale(1.1);
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.toast.exiting {
    animation: slideOutToLeft 0.3s ease-in forwards;
}

@media (max-width: 768px) {
    .toast-container {
        left: 10px;
        right: 10px;
        top: 10px;
    }

    .toast {
        width: 100%;
        min-width: unset;
        max-width: none;
    }
} 