.newspaper-selection {
    padding: 20px;
}

.newspapers-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.newspaper-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.newspaper-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.newspaper-item.selected {
    border-color: #4CAF50;
    background-color: #e8f5e9;
}

.newspaper-item h3 {
    margin: 0 0 10px 0;
    color: #333;
}

.newspaper-item .description {
    color: #666;
    margin: 5px 0;
}

.newspaper-item .price {
    font-weight: bold;
    color: #4CAF50;
    margin: 10px 0 0 0;
}

.copy-option {
    margin: 20px 0;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.copy-option label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.total-price {
    font-size: 1.2em;
    font-weight: bold;
    color: #4CAF50;
    margin: 20px 0;
    padding: 15px;
    background-color: #e8f5e9;
    border-radius: 8px;
    text-align: center;
}

.proceed-button {
    width: 100%;
    padding: 15px;
    font-size: 1.1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.proceed-button:hover {
    background-color: #45a049;
}

.proceed-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
} 