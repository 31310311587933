.step-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 0 auto 2rem;
    padding: 20px 10px;
    position: relative;
}

/* Progress Line - Background */
.step-indicator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3px;
    background: #E2E8F0;
    transform: translateY(-50%);
    z-index: 1;
}

/* Progress Line - Active */
.step-indicator::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 3px;
    background: #22C55E;
    transform: translateY(-50%);
    z-index: 1;
    transition: width 0.5s ease;
    width: calc((100% / 3) * (var(--current-step) - 1));
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 140px;
}

.step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid #E2E8F0;
    color: #64748B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 8px;
    transition: all 0.3s ease;
}

.step.active .step-number {
    background-color: #2B6CB0;
    border-color: #2B6CB0;
    color: white;
    box-shadow: 0 0 0 4px rgba(43, 108, 176, 0.2);
    transform: scale(1.1);
    animation: pulse 2s infinite;
}

.step.completed .step-number {
    background-color: #22C55E;
    border-color: #22C55E;
    color: white;
}

.step-title {
    color: #64748B;
    font-size: 0.9rem;
    text-align: center;
    transition: all 0.3s ease;
    font-weight: 500;
    white-space: nowrap;
}

.step.active .step-title {
    color: #2B6CB0;
    font-weight: 600;
}

.step.completed .step-title {
    color: #22C55E;
    font-weight: 600;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(43, 108, 176, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(43, 108, 176, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(43, 108, 176, 0);
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .step-indicator {
        overflow-x: auto;
        justify-content: flex-start;
        gap: 20px;
        padding: 20px 10px;
        scrollbar-width: none;  /* Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
    }

    .step-indicator::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, Opera */
    }

    .step {
        flex: 0 0 auto;
        min-width: 100px;
    }

    .step-number {
        width: 35px;
        height: 35px;
        font-size: 1rem;
    }

    .step-title {
        font-size: 0.8rem;
    }

    .step-indicator::before,
    .step-indicator::after {
        height: 3px;
        top: 17px;
    }
}

/* Print styles */
@media print {
    .step-indicator {
        display: none;
    }
} 