.subject-selection {
    padding: clamp(16px, 3vw, 24px);
}

.subjects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: clamp(16px, 3vw, 24px);
    margin-top: clamp(20px, 4vw, 32px);
}

.subject-card {
    background-color: var(--background-paper);
    border: 2px solid var(--border-main);
    border-radius: 12px;
    padding: clamp(20px, 4vw, 32px);
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.subject-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(46, 79, 79, 0.1);
    border-color: var(--primary-main);
}

.subject-card h3 {
    margin: 0;
    color: var(--text-primary);
    font-size: clamp(1rem, 2vw, 1.25rem);
}

.subject-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--primary-main);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.subject-card:hover::before {
    transform: scaleX(1);
}

@media (max-width: 768px) {
    .subjects-grid {
        grid-template-columns: 1fr;
    }

    .subject-card {
        padding: 16px;
    }
} 
