/* Base styles */
:root {
    /* Import colors from theme */
    --primary-main: #2B6CB0;
    --primary-light: #4299E1;
    --primary-dark: #2C5282;
    --primary-contrast: #FFFFFF;
    
    --secondary-main: #63B3ED;
    --secondary-light: #90CDF4;
    --secondary-dark: #3182CE;
    
    --accent-main: #4FD1C5;
    --accent-light: #81E6D9;
    --accent-dark: #319795;
    
    --background-default: #EBF8FF;
    --background-paper: #FFFFFF;
    --background-dark: #BEE3F8;
    
    --text-primary: #2D3748;
    --text-secondary: #4A5568;
    --text-disabled: #A0AEC0;
    
    --border-light: #E2E8F0;
    --border-main: #CBD5E0;
    --border-dark: #A0AEC0;
}

.app-container {
    direction: rtl;
    text-align: right;
    max-width: 1440px;
    min-width: 320px;
    margin: 0 auto;
    padding: clamp(10px, 2vw, 20px);
    min-height: 100vh;
    background-color: var(--background-default);
    font-family: 'IRANSans', 'Tahoma', sans-serif;
}

header {
    max-width: 1200px;
    margin: 0 auto 30px;
    padding: 0;
    background: linear-gradient(135deg, #1a365d 0%, #2B6CB0 100%);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(43, 108, 176, 0.15);
    overflow: hidden;
    position: relative;
}

.header-content {
    padding: clamp(30px, 5vw, 50px) clamp(20px, 3vw, 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: relative;
    z-index: 2;
}

/* Decorative elements using gradients */
.header-content::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -20%;
    width: 60%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(-45deg);
    z-index: 1;
}

.header-content::after {
    content: '';
    position: absolute;
    bottom: -50%;
    left: -20%;
    width: 60%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(45deg);
    z-index: 1;
}

header h1 {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    margin: 0;
    color: white;
    text-align: center;
    font-weight: 800;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
}

.header-subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    text-align: center;
    margin: 0;
    position: relative;
    z-index: 2;
    font-weight: 400;
    max-width: 600px;
}

.header-decoration {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to bottom right, transparent 49.5%, var(--background-paper) 50%);
    z-index: 3;
}

main {
    max-width: 1200px;
    margin: -20px auto 0;
    padding: clamp(20px, 4vw, 40px);
    background-color: var(--background-paper);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(43, 108, 176, 0.1);
    position: relative;
    z-index: 4;
}

/* Form Elements */
.form-group {
    margin-bottom: clamp(16px, 3vw, 24px);
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: var(--text-primary);
}

input[type="text"],
input[type="date"],
input[type="time"],
textarea {
    width: 100%;
    padding: clamp(10px, 2vw, 16px);
    font-size: clamp(14px, 2vw, 16px);
    border: 2px solid var(--border-main);
    border-radius: 8px;
    background-color: var(--background-paper);
    color: var(--text-primary);
    transition: all 0.3s ease;
}

input:focus,
textarea:focus {
    outline: none;
    border-color: var(--primary-light);
    box-shadow: 0 0 0 3px rgba(99, 179, 237, 0.2);
}

/* Buttons */
button {
    padding: clamp(10px, 2vw, 16px) clamp(20px, 4vw, 32px);
    font-size: clamp(14px, 2vw, 16px);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    background-color: var(--primary-main);
    color: var(--primary-contrast);
}

button:hover {
    background-color: var(--primary-dark);
    transform: translateY(-1px);
}

button:disabled {
    background-color: var(--text-disabled);
    cursor: not-allowed;
    transform: none;
}

.back-button {
    background-color: var(--secondary-main);
    color: var(--primary-contrast);
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
}

.back-button:hover {
    background-color: var(--secondary-dark);
}

/* Responsive Design */
@media (max-width: 1440px) {
    .app-container {
        padding: 16px;
    }
}

@media (max-width: 768px) {
    .app-container {
        padding: 0;
    }

    header {
        margin: 0;
        border-radius: 0;
        background: linear-gradient(160deg, #1a365d 0%, #2B6CB0 100%);
    }

    .header-content {
        padding: 25px 15px;
    }

    .header-content::before,
    .header-content::after {
        display: none;
    }

    header h1 {
        font-size: 1.5rem;
        line-height: 1.3;
    }

    .header-subtitle {
        font-size: 0.9rem;
        line-height: 1.5;
        padding: 0 10px;
    }

    .header-decoration {
        height: 15px;
    }

    main {
        margin: -15px 0 0 0;
        padding: 20px 15px;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -5px 20px rgba(43, 108, 176, 0.1);
    }

    input[type="text"],
    input[type="date"],
    input[type="time"],
    textarea {
        padding: 12px;
        font-size: 16px;
    }

    button {
        padding: 12px 20px;
        font-size: 16px;
        width: 100%;
    }

    .back-button {
        margin: 10px 0 20px;
        justify-content: center;
    }

    .form-group {
        margin-bottom: 15px;
    }

    label {
        margin-bottom: 6px;
        font-size: 0.95rem;
    }
}

/* Small mobile devices */
@media (max-width: 375px) {
    .header-content {
        padding: 20px 12px;
    }

    header h1 {
        font-size: 1.3rem;
    }

    .header-subtitle {
        font-size: 0.85rem;
    }

    main {
        padding: 15px 12px;
    }
}

/* Print styles */
@media print {
    .app-container {
        padding: 0;
        background: none;
    }

    main {
        box-shadow: none;
        padding: 0;
    }

    .no-print {
        display: none;
    }
} 